<!-- eslint-disable max-len -->
<template>
  <div class="p-4 bg-red-50 rounded-md border border-red-200">
    <div class="flex">
      <div class="ml-2">
        <h3 class="font-medium text-red-800">
          {{ error.message }}
        </h3>
        <div
          v-if="errors.length"
          class="mt-2 text-red-700"
        >
          <ul class="pl-5 list-disc">
            <li
              v-for="(msg, index) in errors"
              :key="`error-${index}`"
              :class="{ 'mt-1': index }"
            >
              {{ msg }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    errors() {
      // eslint-disable-next-line vue/max-len
      return this.error.errors ? Object.values(this.error.errors).flat() : Object.values(this.error).flat();
    },
  },
};
</script>
