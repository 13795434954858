/* eslint-disable max-len */
import Section from '@/components/Section.vue';
import { DateTime } from 'luxon';

export default [
  {
    path: '/',
    redirect: { name: 'login' },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/Login.vue'),
  },
  {
    name: 'password',
    path: '/wachtwoord',
    component: () => import('@/views/Password.vue'),
  },
  {
    name: 'password-reset',
    path: '/wachtwoord-resetten/:token/:email',
    component: () => import('@/views/PasswordReset.vue'),
    props: (route) => ({ token: route.params.token, email: route.params.email }),
  },
  {
    name: 'admin',
    path: '/',
    component: () => import('@/views/Admin.vue'),
    redirect: { name: 'planning' },
    children: [
      {
        name: 'planning',
        path: '/planning',
        component: Section,
        redirect: {
          name: 'planning-day',
          params: {
            year: DateTime.now().year,
            month: DateTime.now().month,
            day: DateTime.now().day,
          },
        },
        children: [
          {
            name: 'planning-week',
            path: '/planning/week/:year/:month/:day',
            component: () => import('@/views/PlanningWeek.vue'),
            meta: { search: true },
            props(route) {
              const date = DateTime.fromObject({
                year: +route.params.year,
                month: +route.params.month,
                day: +route.params.day,
              }).setLocale('nl');

              return { date, iso: date.toISO() };
            },
          },
          {
            name: 'planning-day',
            path: '/planning/dag/:year/:month/:day',
            component: () => import('@/views/PlanningDay.vue'),
            meta: { search: true },
            props(route) {
              const date = DateTime.fromObject({
                year: +route.params.year,
                month: +route.params.month,
                day: +route.params.day,
              }).setLocale('nl');

              return { date, iso: date.toISO() };
            },
          },
        ],
      },
      {
        name: 'work-orders',
        path: '/werkbonnen',
        component: Section,
        redirect: { name: 'work-orders-overview-active' },
        meta: { search: true },
        children: [
          {
            name: 'work-orders-overview-active',
            path: 'actief',
            meta: { search: true },
            component: () => import('@/views/WorkOrders.vue'),
            props: () => ({ filters: { direction: 'asc', status: 'active' } }),
          },
          {
            name: 'work-orders-overview-recurring',
            path: 'herhalend',
            meta: { search: true },
            component: () => import('@/views/WorkOrders.vue'),
            props: () => ({ filters: { direction: 'asc', recurring: true } }),
          },
          {
            name: 'work-orders-overview-printing',
            path: 'printen',
            meta: { search: true },
            component: () => import('@/views/WorkOrders.vue'),
            props: () => ({ filters: { direction: 'asc', status: 'active', printing: true } }),
          },
          {
            name: 'work-orders-overview-closed',
            path: 'gesloten',
            meta: { search: true },
            component: () => import('@/views/WorkOrders.vue'),
            props: () => ({ filters: { direction: 'asc', status: 'closed' } }),
          },
          {
            path: ':workOrder',
            name: 'work-orders-edit',
            component: () => import('@/views/WorkOrdersEdit.vue'),
            props: (route) => ({
              id: +route.params.workOrder,
            }),
          },
          {
            name: 'work-orders-add',
            path: 'toevoegen',
            component: () => import('@/views/WorkOrdersAdd.vue'),
          },
        ],
      },
      {
        name: 'registration',
        path: '/registratie',
        component: () => import('@/views/Registrations.vue'),
        meta: { search: true },
      },
      {
        name: 'equipments',
        path: '/materieel',
        component: () => import('@/views/Equipments.vue'),
        meta: { search: true },
      },
      {
        name: 'users',
        path: '/medewerkers',
        component: () => import('@/views/Users.vue'),
        meta: { search: true },
      },
      {
        path: '/profiel',
        name: 'account',
        component: () => import('@/views/Account.vue'),
      },
    ],
  },
];
