import { createApp } from 'vue';
import apiClient from '@/apiClient';
import router from '@/plugins/router';

import App from '@/App.vue';

/* Global style */
import '../css/styles.css';

const app = createApp(App)
  .use(router);

const globals = app.config.globalProperties;
globals.settings = {
  taxes: [],
  colors: [],
  invoice_lines_warning_threshold: 230,
};

router.isReady().then(async () => {
  app.mount('#app');

  const taxes = await apiClient.get('taxes');
  globals.settings.taxes = taxes.data;

  const colors = await apiClient.get('colors');
  globals.settings.colors = colors.data;
});
